<template>
  <div style="width: 100%; height: 100%;">
    <div :id="id" style="width: 100%; height: 100%;"></div>
  </div>
</template>
<script>

  import highcharts from "highcharts";
  import highcharts3d from "highcharts/highcharts-3d";

  export default {
    props: {
      id: {
        type: String,
      },
      data: {
        type: Array,
      },
      colors: {
        type: Array,
      },
      dataLabel: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
        default: "",
      },
      subtitle: {
        type: String,
        default: "",
      },
      labelFormat: {
        type: String,
        default: "",
      },
      showInLegend: {
        type: Boolean,
        default: false,
      },
      innerSize: {
        type: String,
        default: "",
      },
      size: {
        type: String,
        default: "100%",
      },
      is3D: {
        type: Boolean,
        default: true,
      },
      legend: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        chart: {},
      };
    },
    watch: {
      data: function() {
        this.reDraw();
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        if (this.is3D) {
          highcharts3d(highcharts);
        }
        this.chart = highcharts.chart(this.id, {
          chart: {
            type: "pie",//饼图
            backgroundColor: null,
            options3d: {
              enabled: this.is3D,//使用3d功能
              alpha: 45,//延y轴向内的倾斜角度
              beta: 0,
            },
          },
          credits: {//去掉水印logo
            enabled: false,
          },
          title: {
            text: this.title,//图表的标题文字
          },
          subtitle: {
            text: this.subtitle,//副标题文字
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,//每个扇块能否选中
              cursor: "pointer",//鼠标指针
              depth: 35,//饼图的厚度
              dataLabels: {
                enabled: this.dataLabel,//是否显示饼图的线形tip
                format: this.labelFormat,
              },
              colors: this.colors,
              showInLegend: this.showInLegend,
            },
          },
          series: [
            {
              type: "pie",
              name: "",//统一的前置词,非必须
              data: this.data,
              innerSize: this.innerSize,
              size: this.size,
            },
          ],
          legend: this.legend,
        });
      },
      reDraw() {
        let length = this.chart.series.length;
        for(let i=0; i<length; i++) {
          this.chart.series[i].setData(this.data);
        }
        this.chart.redraw();
      },
    },
  };
</script>
